import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.common.black,
    width: "100vw",
    height: "200px",
    color: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center", // Align items vertically centered
    padding: "0 20px",
    position: "relative",
    bottom: 0,
    left: 0,
    transition: "opacity 0.5s ease",
    opacity: 1,
    zIndex: 9999,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "center",
      height: "auto",
      padding: "20px",
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      height: "auto",
      padding: "20px",
      textAlign: "center",
    },
  },
  visible: {
    opacity: 1,
  },
  leftSide: {
    display: "flex",
    alignItems: "center", // Align items vertically centered
    width: "60%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      flexDirection: "column",
      alignItems: "center", // Align items horizontally centered
      marginBottom: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      alignItems: "center", // Align items horizontally centered
      marginBottom: "20px",
    },
  },
  rightSide: {
    display: "flex",
    alignItems: "center", // Align items vertically centered
    width: "40%",
    marginTop: "28px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "20px",
      justifyContent: "center", // Align items horizontally centered
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "20px",
      justifyContent: "center", // Align items horizontally centered
    },
  },
  downloadAppContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      marginBottom: "20px", // Increase vertical space between sections on medium screens
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginBottom: "20px", // Increase vertical space between sections on small screens
    },
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
  },
  storeButton: {
    width: "118px", // Adjust button width as needed
    height: "38px", // Adjust button height as needed
    cursor: "pointer",
    border: "none",
    background: "none", // Remove background to make image fit
    backgroundColor:"black",
  },

  storeButtonImage: {
    width: "100%", // Image fills entire button width
    height: "100%", // Image fills entire button height
    objectFit: "cover"
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: "20px",
    marginLeft: "20px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      marginBottom: "10px",
      marginRight: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginBottom: "10px",
      marginRight: "0px",
    },
  },
  logo: {
    height: "130px",
    [theme.breakpoints.down("md")]: {
      height: "70px",
      marginRight: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "70px",
      marginRight: "0px",
    },
  },
  groupContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  aboutContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: "35px",
    marginRight: "30px",
    marginBottom: "50px",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      marginBottom: "20px", // Increase vertical space on medium screens
      marginLeft: "55px",
      marginRight: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      marginBottom: "20px", // Increase vertical space on small screens
      marginLeft: "55px",
      marginRight: "0px",
    },
  },
  partnerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: "35px",
    marginBottom: "50px",
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      marginBottom: "20px", // Increase vertical space on medium screens
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      marginBottom: "20px", // Increase vertical space on small screens
    },
  },
  followUsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: "50px",
    marginBottom: "50px",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",
      marginBottom: "20px", // Increase vertical space on medium screens
      marginLeft: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
      marginBottom: "20px", // Increase vertical space on small screens
      marginLeft: "30px",
    },
  },
  aboutHeading: {
    margin: "0 0 3px 0",
    fontSize: "0.875rem",
  },
  partnerHeading: {
    margin: "0 0 3px 0",
    fontSize: "0.875rem",
  },
  followUsHeading: {
    margin: "0 0 3px 0",
    marginBottom: "10px",
    fontSize: "0.875rem",
  },
  downloadAppHeading: {
    margin: "0 0 3px 0",
    fontSize: "0.875rem",
  },
  waitlistHeading: {
    margin: "0 0 3px 0",
    fontSize: "0.875rem",
    marginTop: "10px",
  },
  emailForm: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-start", // Align items to the left
    marginTop: "10px",
  },
  emailInput: {
    padding: "10px",
    marginRight: "10px",
    width: "240px",
    fontSize: "0.875rem",
    border: "1px solid #ccc",
    borderRadius: "4px",
    outline: "none",
    flexGrow: 1,
  },
  joinButton: {
    padding: "12px 25px 10px",
    fontSize: "0.875rem",
    position: "absolute",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginLeft: "186px", // Adjusted to keep the button adjacent to the input
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
  socialIcons: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: "18px",
    height: "18px",
    marginRight: "15px",
    cursor: "pointer",
    objectFit: "cover", 
  },
  img: {
    width: "18px",
    height: "18px",
    marginRight: "15px",
    cursor: "pointer",
  },
  links: {
    display: "flex",
    flexDirection: "column",
    margin: "0",
    "& p": {
      margin: "2px 0",
      cursor: "pointer",
      textDecoration: "none",
      color: "white",
      fontSize: "0.75rem",
    },
  },
  text: {
    marginLeft: "0",
    fontSize: "0.625rem",
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      marginTop: "10px",
    },
  },
  copyRight: {
    textAlign: "center",
    color: "white",
    fontSize: "0.625rem",
  },
}));

export default useStyles;
