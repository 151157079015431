// style.js
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "339px",
    height: "32px",
    gap: "0px",
    borderRadius: "7px 7px 7px 7px",
    opacity: 1,
    backgroundColor: "#D25A26",
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    border: "none", 
    outline: "none",
    color: "#FFFFFF", // Set text color to white
    [theme.breakpoints.down('xl')]: {
      width: "230px", 
      height: "28px",
    },
    [theme.breakpoints.down('lg')]: {
      width: "230px", 
      height: "28px",
    },
    [theme.breakpoints.down('md')]: {
      width: "230px", 
      height: "24px",
    },
    [theme.breakpoints.down('sm')]: {
      width: "100%", 
      height: "30px",
    },
  },
  text: {
    width: "312px",
    height: "17px",
    gap: "0px",
    opacity: 1,
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "16.94px",
    textAlign: "center",
    color: "#FFFFFF",
    [theme.breakpoints.down('lg')]: {
      width: "250px", // Adjust width for large screens
      fontSize: "12px",
    },
    [theme.breakpoints.down('md')]: {
      width: "200px", // Adjust width for medium screens
      fontSize: "10px",
    },
    [theme.breakpoints.down('sm')]: {
      width: "150px", // Adjust width for small screens
      fontSize: "8px",
    },
  },
}));

export default useStyles;
