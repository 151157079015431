import React from "react";
import useStyles from "./style";

const Message = ({ label, name, placeholder, onChange, value }) => {
  const classes = useStyles();

  const handleChange = (e) => {
    const { value } = e.target;
    onChange(name, value); // Communicate changes back to parent component
  };

  return (
    <div className={classes.container}>
      <label className={classes.label}>{label}</label>
      <textarea
        className={classes.textArea}
        name={name}
        placeholder={placeholder}
        rows={5}
        value = {value}
        onChange={handleChange} // Handle change and update parent state
        required
      />
    </div>
  );
};

export default Message;
