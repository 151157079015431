// ApplyButton.js
import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress'; // Import CircularProgress for loader
import useStyles from './style';

const ApplyButton = ({ text, onClick, disabled, loading }) => {
  const classes = useStyles();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button className={`${classes.button} ${disabled ? classes.disabled : ''}`} onClick={handleClick} disabled={disabled || loading}>
      {loading ? (
        <CircularProgress size={24} color="inherit" /> // Render loader while loading is true
      ) : (
        <span className={classes.text}>{text}</span> // Render button text when not loading
      )}
    </button>
  );
};

ApplyButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired, // Add loading prop to indicate loading state
};

ApplyButton.defaultProps = {
  disabled: false,
  loading: false, // Default loading state is false
};

export default ApplyButton;
