import React from "react";
import useStyles from "./style";

const TwoFieldRow = ({ label1, name1, placeholder1, value1, label2, name2, placeholder2, value2, onChange }) => {
  const classes = useStyles();

  const handleInputChange = (event) => {
    onChange(event.target.name, event.target.value);
  };

  return (
    <div className={classes.container}>
      <div className={classes.fieldGroup}>
        <label className={classes.label1}>{label1}</label>
        <input 
          type="text" 
          className={classes.textField1} 
          name={name1} 
          placeholder={placeholder1}
          value = {value1} 
          onChange={handleInputChange} 
          required
        />
      </div>
      <div className={classes.fieldGroup}>
        <label className={classes.label2}>{label2}</label>
        <input 
          type="text" 
          className={classes.textField2} 
          name={name2} 
          placeholder={placeholder2} 
          onChange={handleInputChange}
          value = {value2}
          required 
        />
      </div>
    </div>
  );
};

export default TwoFieldRow;
