import emailjs from 'emailjs-com';

const sendEmail = (templateId, formData, role, isRider, isWaitlist) => {

  let templateParams = {
    ...formData,
    role: role,
    isRider,
    isWaitlist,
  };

  return emailjs.send('service_1d10cqd', templateId, templateParams , 'KHB2Fgr5dT3SDiLQw');
};

export default sendEmail;
