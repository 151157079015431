import React, { useState, useContext } from "react";
import Footer1 from "../../components/Footer1/Footer";
import Form from "../../components/Form/Form";
import LabelTextField from "../../components/LabelTextFIeld/LabelTextField";
import merchantImage from "../../assets/images/merchant.svg";
import TwoFieldRow from "../../components/TwoFieldRow/TwoFieldRow";
import Dropdown from "../../components/DropDown/DropDown";
import useStyles from "./style";
import ApplyButton from "../../components/ApplyButton/ApplyButton";
import sendEmail from "../../emailjsConfig";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { LoginHeader } from "../../components/Header";
import Header from "../../components/Header/Header";
import UserContext from "../../context/User";

const MerchantRegistrationComponent = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    businessName: "",
    phoneNumber: "",
    city: "",
    postalAddress: "",
    email: "",
    country: "",
    businessType: "",
  });

  const [alert, setAlert] = useState({ open: false, severity: "", message: "" });
  const [loading, setLoading] = useState(false); // State for loading indicator
  const { isLoggedIn } = useContext(UserContext);

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.country || !formData.businessType) {
      setAlert({
        open: true,
        severity: "error",
        message: "Please select options for Country and Business Type",
      });
      return; 
    }

    setLoading(true);
    let isRider = false;
    sendEmail("template_zmqe8kl", formData, "New merchant Registration", isRider)
      .then(() => {
        console.log("Email sent successfully!");
        setAlert({ open: true, severity: "success", message: "Form Submitted Successfully" });
        setFormData({
          businessName: "",
          phoneNumber: "",
          city: "",
          postalAddress: "",
          email: "",
          country: "",
          businessType: "",
        });
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
        setAlert({ open: true, severity: "error", message: "Form Not Submitted" });
      })
      .finally(() => {
        setLoading(false); // Reset loading state regardless of success or failure
      });
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <div className={classes.merchantForm}>
        <Form
          picture={merchantImage}
          role="Merchant Partner"
          heading="Become a"
          tagline="Do you sell African food or Groceries in the UK?"
          onSubmit={handleSubmit}
        >
          <LabelTextField
            label="Business Name"
            name="businessName"
            placeholder="E.g John's Restaurant"
            value={formData.businessName}
            onChange={handleInputChange}
          />
          <LabelTextField
            label="Phone Number"
            name="phoneNumber"
            placeholder="E.g. +44 xx xxxx xxxx"
            value={formData.phoneNumber}
            onChange={handleInputChange}
          />
          <TwoFieldRow
            label1="City"
            name1="city"
            placeholder1="E.g London"
            value1={formData.city}
            label2="Postal Address"
            name2="postalAddress"
            placeholder2="E.g. N1 20D"
            value2={formData.postalAddress}
            onChange={handleInputChange}
          />
          <LabelTextField
            label="Email Address"
            name="email"
            placeholder="Enter Your Email"
            value={formData.email}
            onChange={handleInputChange}
          />
          <Dropdown
            label="Country"
            name="country"
            placeholder="Select Country"
            options={['United Kingdom']}
            value={formData.country}
            onChange={handleInputChange}
          />
          <Dropdown
            label="Business Type"
            name="businessType"
            placeholder="Select Business Type"
            options={['Restaurant', 'Grocery Store']}
            value={formData.businessType}
            onChange={handleInputChange}
          />
          <ApplyButton text="Apply" onClick={handleSubmit} loading={loading} />
        </Form>
        <Footer1 />
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alert.open}
        autoHideDuration={6000} // Adjust as needed (milliseconds)
        onClose={handleCloseAlert}
      >
        <Alert severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MerchantRegistrationComponent;
