import React, { useState } from "react";
import useStyles from "./style";
import logo from "../../assets/images/burko-logo.svg";
import facebookIcon from "../../assets/images/borku-facebook.svg";
import twitterIcon from "../../assets/images/borku-x.svg";
import instagramIcon from "../../assets/images/borku-instagram.svg";
import googlePlayStore from "../../assets/images/borku-GooglePlayStore.svg";
import appleStore from "../../assets/images/borku-AppleStore.svg";
import { Link } from "react-router-dom";
import sendEmail from "../../emailjsConfig";
import Snackbar from "@mui/material/Snackbar";

const Footer1 = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage] = useState("");

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let isRider = null; // Boolean to distinguish email source
    let isWaitlist = true;
    const formData = { email };

    sendEmail("template_hbqcjps", formData, "Join Waitlist", isRider, isWaitlist)
      .then(() => {
        console.log("Email sent successfully!");
        setEmailSent(true);
        // Optionally, you can clear the form or show a success message
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
        // Handle error state or display an error message to the user
      });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleAppStoreClick = () => {
    window.open('https://apps.apple.com/us/app/borku/id6642702509', '_blank');
  };
  
  const handlePlayStoreClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.borku.multivendor&pli=1', '_blank');
  };

  return (
    <div className={classes.footer}>
      <div className={classes.leftSide}>
        <div className={classes.logoContainer}>
          <img src={logo} alt="Logo" className={classes.logo} />
        </div>
        <div className={classes.groupContainer}>
          <div className={classes.infoContainer}>
            <div className={classes.aboutContainer}>
              <h4 className={classes.aboutHeading}>About</h4>
              <div className={classes.links}>
                <Link to="/AboutUs" style={{ textDecoration: "none", color: "white", fontSize: 12 }}>
                  About Borku
                </Link>
                <Link to="/ContactUs" style={{ textDecoration: "none", color: "white", fontSize: 12 }}>
                  Contact Us
                </Link>
              </div>
            </div>
            <div className={classes.partnerContainer}>
              <h4 className={classes.partnerHeading}>Partner with Us</h4>
              <div className={classes.links}>
                <Link to="/" style={{ textDecoration: "none", color: "white", fontSize: 12 }}>
                  Become a Customer
                </Link>
                <Link to="/merchantRegistration" style={{ textDecoration: "none", color: "white", fontSize: 12 }}>
                  Become a Partner
                </Link>
                <Link to="/riderRegistration" style={{ textDecoration: "none", color: "white", fontSize: 12 }}>
                  Become a Rider
                </Link>
              </div>
            </div>
            <div className={classes.followUsContainer}>
              <h4 className={classes.followUsHeading}>Follow Us</h4>
              <div className={classes.socialIcons}>
                <a href="https://www.facebook.com/profile.php?id=61558760867335" target="_blank" rel="noopener noreferrer">
                  <img src={facebookIcon} alt="Facebook" className={classes.icon} />
                </a>
                  <img src={twitterIcon} alt="Twitter" className={classes.icon} />
                <a href="https://www.instagram.com/borku_uk?igsh=ZDZmb2lzNDQ3OXpy" target="_blank" rel="noopener noreferrer">
                  <img src={instagramIcon} alt="Instagram" className={classes.icon} />
                </a>
              </div>
            </div>
          </div>
          <div className={classes.copyRight}>
            © All rights reserved. Borku 2024
          </div>
        </div>
      </div>
      <div className={classes.rightSide}>
        <div className={classes.downloadAppContainer}>
          <h4 className={classes.downloadAppHeading}>Download our App</h4>
          <div className={classes.buttonRow}>
            <button onClick={handleAppStoreClick} className={classes.storeButton}>
              <img src={appleStore} alt="Apple Store" style={{ height: 38, width: 112 }}/>
            </button>
            <button onClick={handlePlayStoreClick} className={classes.storeButton}>
              <img src={googlePlayStore} alt="Google Play Store" style={{ height: 38, width: 112 }} />
            </button>
          </div>
          <h6 className={classes.waitlistHeading}>Join our mailing list</h6>
          {emailSent ? (
            <p>Thank you for joining our mailing list!</p>
          ) : (
            <form className={classes.emailForm} onSubmit={handleSubmit}>
              <input
                type="email"
                name="email"
                placeholder="Enter your Email"
                className={classes.emailInput}
                required
                value={email}
                onChange={handleInputChange}
              />
              <button type="submit" className={classes.joinButton}>
                Join
              </button>
            </form>
          )}
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        ContentProps={{
          sx: {
            backgroundColor: "#FFD8B8",
            color: "black",
          },
        }}
        message={snackbarMessage}
      />
    </div>
  );
};

export default Footer1;
