import React, { useState, useContext } from "react";
import Footer1 from "../../components/Footer1/Footer";
import Form from "../../components/Form/Form";
import LabelTextField from "../../components/LabelTextFIeld/LabelTextField";
import riderImage from "../../assets/images/rider.svg";
import TwoFieldRow from "../../components/TwoFieldRow/TwoFieldRow";
import QuestionWithRadioButtons from "../../components/QuestionWithRadioButtons/QuestionWithRadioButtons";
import useStyles from "./style";
import ApplyButton from "../../components/ApplyButton/ApplyButton";
import sendEmail from "../../emailjsConfig";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { LoginHeader } from "../../components/Header";
import Header from "../../components/Header/Header";
import UserContext from "../../context/User";

const RiderRegistrationComponent = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    fullName: "",
    phoneNumber: "",
    city: "",
    postalAddress: "",
    email: "",
    selfEmployed: "",
    rightToWork: "",
    vehicleAndInsurance: "",
    bankAccount: "",
    age: "",
    backgroundCheck: "",
  });

  const [alert, setAlert] = useState({ open: false, severity: "", message: "" });
  const [loading, setLoading] = useState(false); // State for loading indicator
  const { isLoggedIn } = useContext(UserContext);

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let isRider = true;
    sendEmail("template_zmqe8kl", formData, "New Rider Registration", isRider)
      .then(() => {
        console.log("Email sent successfully!");
        setAlert({ open: true, severity: "success", message: "Form Submitted Successfully" });
        setFormData({
          fullName: "",
          phoneNumber: "",
          city: "",
          postalAddress: "",
          email: "",
          selfEmployed: "",
          rightToWork: "",
          vehicleAndInsurance: "",
          bankAccount: "",
          age: "",
          backgroundCheck: "",
        });
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
        setAlert({ open: true, severity: "error", message: "Form Not Submitted" });
      })
      .finally(() => {
        setLoading(false); // Reset loading state regardless of success or failure
      });
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  return (
    <>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <div className={classes.riderForm}>
        <Form
          picture={riderImage}
          role="Rider"
          heading="Become a"
          tagline="Ride with Borku and maximize your earning."
          onSubmit={handleSubmit} // Pass handleSubmit to Form component's onSubmit prop
        >
          <LabelTextField
            label="Full Name"
            name="fullName"
            placeholder="E.g John Doe"
            value={formData.fullName}
            onChange={handleInputChange}
          />
          <LabelTextField
            label="Phone Number"
            name="phoneNumber"
            placeholder="E.g. +44 xx xxxx xxxx"
            value={formData.phoneNumber}
            onChange={handleInputChange}
          />
          <TwoFieldRow
            label1="City"
            name1="city"
            placeholder1="E.g London"
            value1={formData.city}
            label2="Postal Address"
            name2="postalAddress"
            placeholder2="E.g. N1 20D"
            value2={formData.postalAddress}
            onChange={handleInputChange}
          />
          <LabelTextField
            label="Email Address"
            name="email"
            placeholder="Enter Your Email"
            value={formData.email}
            onChange={handleInputChange}
          />
          <QuestionWithRadioButtons
            question="Are you self employed?"
            name="selfEmployed"
            value={formData.selfEmployed}
            onChange={handleInputChange}
          />
          <QuestionWithRadioButtons
            question="Do you have Proof of right to work in the UK?"
            name="rightToWork"
            value={formData.rightToWork}
            onChange={handleInputChange}
          />
          <QuestionWithRadioButtons
            question="Do you have a bike or car - plus licence and the correct insurance?"
            name="vehicleAndInsurance"
            value={formData.vehicleAndInsurance}
            onChange={handleInputChange}
          />
          <QuestionWithRadioButtons
            question="Do you have a UK bank account?"
            name="bankAccount"
            value={formData.bankAccount}
            onChange={handleInputChange}
          />
          <QuestionWithRadioButtons
            question="Are you 18+ years of age?"
            name="age"
            value={formData.age}
            onChange={handleInputChange}
          />
          <QuestionWithRadioButtons
            question="Can we run a background check on you?"
            name="backgroundCheck"
            value={formData.backgroundCheck}
            onChange={handleInputChange}
          />
          <ApplyButton text="Apply" onClick={handleSubmit} loading={loading} />
        </Form>
        <Footer1 />
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alert.open}
        autoHideDuration={6000} // Adjust as needed (milliseconds)
        onClose={handleCloseAlert}
      >
        <Alert severity={alert.severity} sx={{ width: '100%' }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default RiderRegistrationComponent;
