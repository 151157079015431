// Import the functions you need from the SDKs you need
import * as firebase from "firebase/app";
import { getMessaging, isSupported } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAudlDtt5J4_1k3Lj_HMCouwr5ILQYKiNw",
  authDomain: "borku-f60d0.firebaseapp.com",
  projectId: "borku-f60d0",
  storageBucket: "borku-f60d0.appspot.com",
  messagingSenderId: "931563846404",
  appId: "1:931563846404:web:ee9b46bad6fb90b0712e51",
  measurementId: "G-BK8V4EZMN4"
};

export const initialize = () => {
  // Initialize Firebase
  const app = firebase.initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  return messaging;
};

export const isFirebaseSupported = async () => {
  return await isSupported();
};
