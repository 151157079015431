import React, { useContext } from "react";
import Form from "../../components/Form/Form";
import Footer1 from "../../components/Footer1/Footer";
import AboutusImage from "../../assets/images/aboutus.svg";
import { LoginHeader } from "../../components/Header";
import Header from "../../components/Header/Header";
import UserContext from "../../context/User";

const AboutUs = () => {
  const { isLoggedIn } = useContext(UserContext);

  return (
    <>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <div>
        <Form
          picture={AboutusImage}
          heading="About Us."
          tagline="Borku is your go-to for authentic African cuisine in the UK. From flavourful dishes to essential groceries. We celebrate the rich culinary heritage of home. Order now with Borku for doorstep delivery."
        >
        </Form>
        <Footer1 />
      </div>
    </>
  );
};

export default AboutUs;
