import { Box, Divider, Menu, MenuItem, useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import useStyle from "./styles";
import { useTheme } from "@emotion/react";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";
import Logo from "../../../assets/icons/Logo.svg";

function LoginDesktopHeader({ title, showIcon, showCart = false }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyle();
  const theme = useTheme();
  const location = useLocation();
  const small = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorElGetStarted, setAnchorElGetStarted] = React.useState(null);
  const [anchorElAbout, setAnchorElAbout] = React.useState(null);

  const handleGetStartedClick = (event) => {
    setAnchorElGetStarted(event.currentTarget);
  };

  const handleAboutClick = (event) => {
    setAnchorElAbout(event.currentTarget);
  };

  const handleGetStartedClose = () => {
    setAnchorElGetStarted(null);
  };

  const handleAboutClose = () => {
    setAnchorElAbout(null);
  };

  const handleMenuItem = (path) => {
    handleGetStartedClose()
    handleAboutClose()
    navigate(path)
  }

  return (
    <AppBar elevation={0} position="fixed">
      <Toolbar className={classes.toolbar}>
        <Box sx={{display: 'flex', gap: small ? 0 : 4}}>
        <RouterLink
          to={location.pathname === "/checkout" ? "/restaurant-list" : "/"}
          className={classes.linkDecoration}
        >
          <a href="#/">
            <img height={small ? "30" : "50"} width={small ? "30" : "50"} src={Logo} alt="Borku" />
          </a>
          {
            small ? null : (
          <Typography
            variant="h6"
            color={theme.palette.common.white}
            className={classes.font700}
            style={{ marginLeft: "8px" }}
          >
            {title}
          </Typography>

            )
          }
        </RouterLink>
        <Button
        id="get-started-button"
        aria-controls={Boolean(anchorElGetStarted) ? "get-started-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorElGetStarted) ? "true" : undefined}
        onClick={handleGetStartedClick}
        sx={{ color: theme.palette.common.white, fontSize: small ? 8 : 11 }}
      >
        Get Started
      </Button>
      <Menu
        id="get-started-menu"
        anchorEl={anchorElGetStarted}
        open={Boolean(anchorElGetStarted)}
        onClose={handleGetStartedClose}
        MenuListProps={{
          "aria-labelledby": "get-started-button",
        }}
      >
        <MenuItem onClick={()=>handleMenuItem('/')} sx={{ color: 'black' }}>Become a Customer</MenuItem>
        <MenuItem onClick={()=>handleMenuItem('/merchantRegistration')} sx={{ color: 'black' }}>Become a Merchant</MenuItem>
        <MenuItem onClick={()=>handleMenuItem('/riderRegistration')} sx={{ color: 'black' }}>Become a Rider</MenuItem>
      </Menu>
      <Button
        id="about-button"
        aria-controls={Boolean(anchorElAbout) ? "about-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={Boolean(anchorElAbout) ? "true" : undefined}
        onClick={handleAboutClick}
        sx={{ color: theme.palette.common.white, fontSize: small ? 8 : 11 }}
      >
        About
      </Button>
      <Menu
        id="about-menu"
        anchorEl={anchorElAbout}
        open={Boolean(anchorElAbout)}
        onClose={handleAboutClose}
        MenuListProps={{
          "aria-labelledby": "about-button",
        }}
      >
        <MenuItem onClick={()=>handleMenuItem('/AboutUs')} sx={{ color: 'black' }}>About Borku</MenuItem>
        <MenuItem onClick={()=>handleMenuItem('/ContactUs')} sx={{ color: 'black' }}>Contact</MenuItem>
      </Menu>
        </Box>
        
        
        <Box className={classes.flex}>
          {showIcon && (
            <>
              <Divider flexItem orientation="vertical" light />
              <RouterLink to={"/login"} className={classes.linkDecoration}>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  style={{ background: theme.palette.primary.main, width: 80 }}
                >
                  <PersonIcon style={{ color: theme.palette.common.white, height: 20, width: 20 }} />
                  <Typography
                    variant="caption"
                    color="white"
                    className={`${classes.ml} ${classes.font700}`}
                  >
                    {t("loginBtn")}
                  </Typography>
                </Button>
              </RouterLink>
              <Divider flexItem orientation="vertical" light />
            </>
          )}
          {showCart && (
            <Box style={{ alignSelf: "center" }}>
              <RouterLink to="/" className={classes.linkDecoration}>
                <Button>
                  <LocalMallIcon
                    style={{ color: theme.palette.common.white }}
                  />
                </Button>
              </RouterLink>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default React.memo(LoginDesktopHeader);
