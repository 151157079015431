import React from "react";
import useStyles from "./style"; 

const QuestionWithRadioButtons = ({ question, name, value, onChange }) => {
  const classes = useStyles();

  const handleRadioChange = (event) => {
    onChange(name, event.target.value);
  };

  return (
    <div className={classes.container}>
      <div className={classes.question}>{question}</div>
      <div className={classes.radioContainer}>
        <div>
          <input 
            type="radio" 
            id={`${name}-yes`} 
            name={name} 
            value="yes" 
            className={classes.radioButton} 
            checked= {value === "yes"}
            onChange={handleRadioChange}
            required
          />
          <label htmlFor={`${name}-yes`} className={classes.radioLabel}>Yes</label>
        </div>
        <div>
          <input 
            type="radio" 
            id={`${name}-no`} 
            name={name} 
            value="no" 
            className={classes.radioButton} 
            checked={value === "no"}
            onChange={handleRadioChange}
            required
          />
          <label htmlFor={`${name}-no`} className={classes.radioLabel}>No</label>
        </div>
      </div>
    </div>
  );
};

export default QuestionWithRadioButtons;
