/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";

import Footer from "../../components/Footer/Footer";
import { Header, LoginHeader } from "../../components/Header";
import UserContext from "../../context/User";
import useStyle from "./styles";
import Analytics from "../../utils/analytics";

function PrivacyPolicy() {
  useEffect(async () => {
    await Analytics.track(Analytics.events.NAVIGATE_TO_PRIVACY_POLICY);
  }, []);
  const classes = useStyle();
  const { isLoggedIn } = useContext(UserContext);
  return (
    <Grid className={classes.root}>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <Grid container className={classes.mainContainer}>
        <Box className={classes.imageContainer}>
          <Typography
            variant="h4"
            color="textPrimary"
            align="center"
            className={classes.title}
          >
            Privacy Policy
          </Typography>
        </Box>
        <Grid container item xs={12} className={classes.mainContainer}>
          <Grid item xs={1} md={1} />
          <Grid container item xs={10} sm={10} md={9}>
            <Typography variant="subtitle1" className={classes.boldText}>
              Our Privacy Policy
            </Typography>
            <Typography
              color="textSecondary"
              variant="subtitle2"
              className={classes.MV3}
            >

              {/* <a
                href="https://enatega.com"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.linkDecoration}
              >
                <Typography
                  variant="caption"
                  color="primary"
                  className={classes.link}
                >
                  {"https://enatega.com"}
                </Typography>
              </a> */}
              Your privacy is important to us. To better protect your privacy, we provide this notice
              explaining our information practices and the choices you can make about the way your
              information is collected and used.
              <Typography variant="subtitle2" className={classes.MV3}>
                Borku is a product/platform owned by Borku UK Limited.
              </Typography>
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              1. What data we store, and how we use it.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              ("We") are committed to protecting and respecting your privacy and protecting your data.
              This policy sets out the basis on which any personal data we collect from you, or that you
              provide to us, will be processed by us. Please read the following carefully to understand our
              views and practices regarding your personal data and how we will treat it. By visiting
              borku.co.uk you are accepting and consenting to the practices described in this policy.
              By visiting this website, you consent to the collection and use of your Personal Data as
              described herein. If you do not agree with the terms set out herein, please do not visit this
              website.
            </Typography>
            
            <Typography variant="subtitle1" className={classes.boldText}>
              2. How we collect your personal data
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              We acquire, process and retain your personal data, as well as personal data about devices you
              use to navigate to Borku platforms (e.g. your computer, mobile phone or other means), which
              you provide to us by ordering, creating an account or otherwise by contacting us.
            </Typography>
            <Typography variant="subtitle2">
              We may allow you to use social media sites to create your account or link your account to the
              relevant social media site. These social media sites may provide us with automatic access to
              certain personal data they hold about you (e.g. content viewed by you, content liked by you,
              and information regarding the advertisements you have been shown or may have clicked on).
              Where we receive such information, we may use this information to further personalise your
              experience with us in line with your marketing preferences.



            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              3. What personal data we process and why
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Personal data, in this Statement also referred to as “your data”, means any information
              or set of information from which we are able, directly or indirectly, to personally
              identify you, in particular by reference to an identifier, e.g. name and surname, email
              address, phone number, etc. It does not include data where the identity has been
              removed (anonymous data).
            </Typography>
            <Typography variant="subtitle2">
              Whenever you interact with Borku via our Services we collect and process your
              personal data. Borku may process your personal data for the following purposes:
            </Typography>

            <Typography variant="subtitle1" className={classes.boldText}>
              Ordering process
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              We process the personal data you provide to us when you place your order. This personal
              data is necessary to execute your order, confirm your order, and to assess your order,
              payment, and a possible refund. Your information (such as the items you add to your basket)
              allow us to process your order and send you an accurate bill. It also helps us and any Partner
              you order with contact you when necessary. Some Partners on our platform use our third-
              party delivery companies which may use your information to provide you with status updates
              on the delivery of your order. Depending on the circumstance, we may rely on your consent
              or the fact that the processing is necessary to fulfil a contract with you, or to comply with
              law. We may process the following personal data in the ordering process:
              {/* <Typography
                variant="caption"
                color="primary"
                className={classes.link}
              >
                {" info@enatega.com"}
              </Typography> */}
              <ul >
                <li>
                  Name
                </li>
                <li>
                  Address data
                </li>
                <li>
                  Contact data
                </li>
                <li>
                  Order and transaction data
                </li>
                <li>Payment data</li>
                <li>
                  Identity and/or age verification data
                </li>
              </ul>
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              Partner & Courier reviews
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              After your order, you may be asked to submit a review of Partners and/or couriers.
              Depending on the circumstance, we may rely on your consent or the fact that the processing
              is necessary to fulfil a contract with you, or to comply with law.
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              Borku processes the personal data you provide when submitting a review. You can withdraw
              your consent by contacting us. We may process the following personal data when you post a
              Partner and/or courier review:
              <ul>
                <li>
                  Name
                </li>
                <li>
                  Contact data
                </li>
                <li>
                  Review
                </li>
              </ul>
              When your review is published, the review, and your first name, may be made available to
              couriers, Partners and/or the public.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              Customer Services and Support
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              When you contact our customer services support, we will use the personal data you provide
              to answer your question or handle your complaint. We may collect personal data under call
              recordings to provide customer support, including to investigate and address user concerns
              and to monitor and improve our customer support responses and processes.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              Marketing
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              We also process your personal data to be able to send you marketing communications and
              notifications to administer, support, improve and develop our Services. There may be cases
              where Borku may process your personal data for promoting Borku vendors and third-party
              offers, products, and services
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              We may process the following personal data for marketing purposes:
              <ul >
                <li>
                  Name
                </li>
                <li>
                  Address data
                </li>
                <li>
                  Contact data
                </li>
                <li>
                  Order and transaction data
                </li>
                <li>Review(s)</li>
                <li>
                  Campaing data
                </li>
                <li>Device ID
                </li>
                <li>
                  Cookie and Technology data
                </li>
              </ul>
              To enable us to personalise your user experience with Borku and optimise our Services
              offered to you, we may use automated decision making and profiling for marketing purposes.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              Customer Research
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              To ensure that our Services are consistent with your preferences, your personal data
              may be used for purposes such as conducting product, user research or satisfaction
              surveys.
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
              Fraud Prevention
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
              We also process personal data to prevent misuse on and via our Services. Depending on the
              circumstance, we may rely on your consent or the fact that the processing is necessary to
              fulfil a contract with you, to comply with law or our legitimate interest (or equivalent under
              applicable data protection law). We may process following personal data for fraud prevention
              purposes:
              <ul >
                <li>
                  Name
                </li>
                
                <li>
                Address data
                </li>
                <li>
                  Order and transaction data
                </li>
                <li>Payment data
</li>
                <li>
                Device information
                </li>
                <li>IP address
                </li>
                <li>
                Browser information
                </li>
              </ul>
            </Typography>
            <Typography variant="subtitle1" className={classes.boldText}>
            Age
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
            Our Services are not intended for persons defined as underage by applicable local laws, nor 
do we intend to collect personal data of visitors who are under the defined legal age. 
However, we are unable to verify visitors’ ages. We therefore advise parents and/or legal 
guardians to monitor their children's online activities, to prevent their personal data being 
collected without parental or guardian consent. If you feel that we have collected personal 
data of a minor without consent, please contact us via our 
  <a
                href="https://borku.co.uk/#/ContactUs"
                target="_blank"
                rel="noopener noreferrer"
                className={classes.linkDecoration}
              >
                <Typography
                  variant="caption"
                  color="primary"
                  className={classes.link}
                >
                  {" Contact Us "}
                </Typography>
              </a>
              
            </Typography>
            <Typography variant="subtitle2" className={classes.MV3}>
            We shall endeavour to put in place appropriate and proportionate measures to ensure a high 
level of privacy, safety, and security of minors in our platforms in accordance with applicable
laws.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

export default React.memo(PrivacyPolicy);
