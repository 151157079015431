import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '20px',
    marginTop: '20px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  fieldGroup: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px',
  },
  label1: {
    width: '77.61px',
    height: '12px',
    gap: '0px',
    opacity: '1',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '12.1px',
    textAlign: 'left',
    color: '#000000',
    display: 'block',
    marginBottom: '5px',
    padding: '2px 4px',
    borderRadius: '3px',
    [theme.breakpoints.down('md')]: {
      fontSize: '9px', // Adjust font size for medium screens
      width: '70px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px', // Adjust font size for small screens
      width: '60px',
    },
  },
  textField1: {
    width: '130px',
    height: '20px',
    gap: '0px',
    borderRadius: '7px',
    border: '1px solid #E2E2E2',
    boxShadow: '1px 0px 5px 0px #0000001A',
    padding: '10px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    '&::placeholder': {
      fontFamily: 'Inter',
      fontSize: '10px',
      fontWeight: 300,
      lineHeight: '12.1px',
      textAlign: 'left',
      color: '#B5B5B5',
    },
    [theme.breakpoints.down('lg')]: {
      width: '80px', // Adjust width for large screens
      height: '30px',
      fontSize: '12px',
      padding: '8px',
    },
    [theme.breakpoints.down('md')]: {
      width: "200px", // Adjust width for medium screens
      height: "30px",
      fontSize: "12px",
      padding: "8px",
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%', // Adjust width for small screens
      height: '30px',
      fontSize: '8px',
    },
  },
  label2: {
    width: '130px',
    height: '12px',
    gap: '0px',
    opacity: '1',
    fontFamily: 'Inter',
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '12.1px',
    textAlign: 'left',
    color: '#000000',
    display: 'block',
    marginBottom: '5px',
    padding: '2px 4px',
    borderRadius: '3px',
    [theme.breakpoints.down('md')]: {
      fontSize: '9px', // Adjust font size for medium screens
      width: '70px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '8px', // Adjust font size for small screens
      width: '60px',
    },
  },
  textField2: {
    width: '130px',
    height: '20px',
    gap: '0px',
    borderRadius: '7px',
    border: '1px solid #E2E2E2',
    boxShadow: '1px 0px 5px 0px #0000001A',
    padding: '10px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '16px',
    '&::placeholder': {
      fontFamily: 'Inter',
      fontSize: '10px',
      fontWeight: 300,
      lineHeight: '12.1px',
      textAlign: 'left',
      color: '#B5B5B5',
    },
    [theme.breakpoints.down('lg')]: {
      width: '80px', // Adjust width for large screens
      height: '30px',
      fontSize: '12px',
      padding: '8px',
    },
    [theme.breakpoints.down('md')]: {
      width: "200px", // Adjust width for medium screens
      height: "30px",
      fontSize: "12px",
      padding: "8px",
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%', // Adjust width for small screens
      height: '30px',
      fontSize: '8px',
    },
  },
}));

export default useStyles;
