import React, { useState, useRef, useEffect } from 'react';
import useStyles from './style'; 
import ArrowDown from '../../assets/images/vectordropdown.svg'; 
import ArrowUp from '../../assets/images/arrowupward.svg';

const Dropdown = ({ label, name, placeholder, options, value, onChange }) => {
  const classes = useStyles();
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value || ''); // Track selected option
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onChange(name, option);
    setShowOptions(false);
  };

  const handleDropdownClick = () => {
    setShowOptions(!showOptions); // Toggle dropdown visibility
  };

  return (
    <div ref={dropdownRef} className={classes.container}>
      <label className={classes.label}>{label}</label>
      <div className={classes.dropdown} onClick={handleDropdownClick}>
        <span>{selectedOption || placeholder}</span>
        <img
          src={showOptions ? ArrowUp : ArrowDown} // Use ArrowUp when dropdown is open
          alt="dropdown-arrow"
          className={classes.arrow}
        />
        <div className={`${classes.dropdownContent} ${showOptions ? classes.show : ''}`}>
          {options.map((option, index) => (
            <div key={index} onClick={() => handleOptionClick(option)}>
              {option}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
