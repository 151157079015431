import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    marginBottom: "20px",
    marginTop: "15px",
  },
  label: {
    width: "142px",
    height: "12px",
    gap: "0px",
    opacity: "1", // Set to 1 for visibility
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "12.1px",
    textAlign: "left",
    color: "#000000", // Black text color
    display: "block",
    marginBottom: "5px", // Space between label and text field
    padding: "2px 4px", // Padding for better visibility
    borderRadius: "3px", // Optional: small border radius for better aesthetics
    [theme.breakpoints.down("md")]: {
      fontSize: "11px", // Adjust font size for medium screens
      width: "130px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px", // Adjust font size for small screens
      width: "120px",
    },
  },
  dropdown: {
    width: "300px",
    height: "20px",
    gap: "0px",
    borderRadius: "7px",
    border: "1px solid #E2E2E2",
    boxShadow: "1px 0px 5px 0px #0000001A",
    padding: "10px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    position: "relative",
    [theme.breakpoints.down("lg")]: {
      width: "200px", // Adjust width for large screens
      height: "30px",
      fontSize: "12px",
      padding: "8px",
    },
    [theme.breakpoints.down("md")]: {
      width: "200px", // Adjust width for medium screens
      height: "30px",
      fontSize: "12px",
      padding: "8px",
    },
    [theme.breakpoints.down("sm")]: {
     width: '100%',
    },
  },
  dropdownContent: {
    display: "none",
    position: "absolute",
    backgroundColor: "#ffffff",
    minWidth: "100%",
    boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
    zIndex: 1,
    borderRadius: "0px 0px 7px 7px", // Rounded bottom corners
    overflow: "hidden", // Ensure options don't overflow
    border: "1px solid #E2E2E2", // Optional: border around dropdown options
    borderTop: "none", // Optional: hide top border
    marginTop: "-1px", // Optional: adjust to align with text field
    "& div": {
      padding: "10px", // Adjust padding as needed
      borderBottom: "1px solid #E2E2E2", // Separator line between options
      "&:last-child": {
        borderBottom: "none", // Remove border from last option
      },
      "&:hover": {
        backgroundColor: "#f1f1f1", // Hover background color
        cursor: "pointer", // Pointer cursor on hover
      },
    },
  },
  show: {
    display: "block",
  },
  arrow: {
    width: "9px",
    height: "6px",
  },
}));

export default useStyles;
