import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width: "100%",
    marginBottom: "20px",
    marginTop: "15px",
  },
  label: {
    width: "142px",
    height: "12px",
    gap: "0px",
    opacity: "1", // Set to 1 for visibility
    fontFamily: "Inter",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "12.1px",
    textAlign: "left",
    color: "#000000", // Black text color
    display: "block",
    marginBottom: "5px", // Space between label and text field
    padding: "2px 4px", // Padding for better visibility
    borderRadius: "3px", // Optional: small border radius for better aesthetics
    [theme.breakpoints.down("md")]: {
      fontSize: "11px", // Adjust font size for medium screens
      width: "130px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px", // Adjust font size for small screens
      width: "120px",
    },
  },
  textArea: {
    width: "100%",
    minHeight: "80px", // Adjust minimum height as needed
    borderRadius: "7px",
    border: "1px solid #E2E2E2",
    boxShadow: "1px 0px 5px 0px #0000001A",
    padding: "10px",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "1.5",
    resize: "vertical", // Allow vertical resizing of textarea
    "&::placeholder": {
      fontFamily: "Inter",
      fontSize: "10px",
      fontWeight: 300,
      lineHeight: "12.1px",
      textAlign: "left",
      color: "#B5B5B5",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
      padding: "8px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      padding: "8px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      padding: "6px",
    },
  },
}));

export default useStyles;
