import React, { useState, useContext } from "react";
import Footer1 from "../../components/Footer1/Footer";
import Form from "../../components/Form/Form";
import TwoEqualFieldRow from "../../components/TwoEqualFieldRow/TwoEqualFieldRow";
import Dropdown from "../../components/DropDown/DropDown";
import Message from "../../components/Message/Message";
import ApplyButton from "../../components/ApplyButton/ApplyButton";
import useStyles from "./style";
import sendEmail from "../../emailjsConfig";
import ContactUs from "../../assets/images/ContactUS.svg";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { LoginHeader } from "../../components/Header";
import Header from "../../components/Header/Header";
import UserContext from "../../context/User";

const ContactUsComponent = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    reason: "",
    message: "",
  });

  const [alert, setAlert] = useState({ open: false, severity: "", message: "" });
  const [loading, setLoading] = useState(false); // State for loading indicator
  const { isLoggedIn } = useContext(UserContext);

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.reason) {
      setAlert({
        open: true,
        severity: "error",
        message: "Please select a Reason",
      });
      return; 
    }

    setLoading(true); // Set loading to true when form is submitting
    let isRider = null;
    let isWaitlist = false;
    sendEmail("template_hbqcjps", formData, "Contact Us", isRider, isWaitlist)
      .then(() => {
        console.log("Email sent successfully!");
        setAlert({ open: true, severity: "success", message: "Form Submitted Successfully" });
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          reason: "",
          message: "",
        });
      })
      .catch((error) => {
        console.error("Failed to send email:", error);
        setAlert({ open: true, severity: "error", message: "Form Not Submitted" });
      })
      .finally(() => {
        setLoading(false); // Reset loading state regardless of success or failure
      });
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert({ ...alert, open: false });
  };

  return (
    <>
      {isLoggedIn ? <Header /> : <LoginHeader showIcon />}
      <div className={classes.contactForm}>
        <Form
          picture={ContactUs}
          contactus={true}
          contactHeading="Get in touch with us."
          contactText="Need to get in touch with us? Either fill out the form with your inquiry or reach us via email on"
          contactEmail="sales@borku.co.uk"
          contactWhatsapp="+44 789 423 1255" 
          onSubmit={handleSubmit}
        >
          <TwoEqualFieldRow
            label1="First Name"
            name1="firstName"
            placeholder1="John"
            value1={formData.firstName}
            label2="Last Name"
            name2="lastName"
            placeholder2="Smith"
            value2={formData.lastName}
            onChange={handleInputChange}
          />
          <TwoEqualFieldRow
            label1="Email Address"
            name1="email"
            placeholder1="john@example.com"
            value1={formData.email}
            label2="Phone Number"
            name2="phoneNumber"
            placeholder2="123-456-7890"
            value2={formData.phoneNumber}
            onChange={handleInputChange}
          />
          <Dropdown
            label="Reason"
            name="reason"
            placeholder="Select Reason"
            options={["Rider", "Merchant", "Something else"]}
            value={formData.reason}
            onChange={handleInputChange}
          />
          <Message
            label="Your Message"
            name="message"
            placeholder="Write your message here"
            value={formData.message}
            onChange={handleInputChange}
          />
          <ApplyButton text="Apply" onClick={handleSubmit} loading={loading} /> {/* Pass loading state */}
        </Form>
        <Footer1 />
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alert.open}
        autoHideDuration={6000} // Adjust as needed (milliseconds)
        onClose={handleCloseAlert}
      >
        <Alert severity={alert.severity} sx={{ width: "100%" }}>
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactUsComponent;
