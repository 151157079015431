import React from "react";
import useStyles from "./style";

const LabelTextField = ({ label, name, placeholder, value , onChange }) => {
  const classes = useStyles();

  const handleInputChange = (event) => {
    onChange(event.target.name, event.target.value);
  };

  return (
    <div className={classes.container}>
      <label className={classes.label}>{label}</label>
      <input 
        type="text" 
        className={classes.textField} 
        name={name} 
        placeholder={placeholder} 
        value = {value}
        onChange={handleInputChange} 
        required
      />
    </div>
  );
};

export default LabelTextField;
