import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    width: '100%',
  },
  question: {
    width: 'auto',
    height: '12px',
    fontFamily: 'Inter',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '12px',
    textAlign: 'left',
    background: '#FFFFFF',
    color: '#000000',
    marginBottom: '12px',
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px',
  },
  radioLabel: {
    width: '20px',
    height: '10px',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '9.68px',
    textAlign: 'left',
    background: '#FFFFFF',
    color: '#000000',
    marginRight: '10px',
  },
  radioButton: {
    width: '8px',
    height: '8px',
    borderRadius: '3px',
    border: '1px solid #E6E6E6',
    backgroundColor: '#D25A26',
    cursor: 'pointer',
    accentColor: theme.palette.primary.main,
  },
}));

export default useStyles;
