import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    alignItems: "flex-start",
    backgroundColor: "#edf4fa",
    padding: "20px",
    width: '100%',
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "auto",
      alignItems: "center"
    },
  },
  imageContainer: {
    width: "28%", // Default width for the image container
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    overflow: "hidden",
    backgroundColor: "#ffffff",
    borderRadius: "10px", // Default border radius
    marginTop: "100px",
    marginBottom: "100px",
    marginLeft: "200px",
    [theme.breakpoints.down("md")]: {
      width: "40%", // Decrease width for medium screens
      marginLeft: "50px", // Adjust margin for medium screens
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide image container on small screens
    },
  },
  picture: {
    width: "100%",
    height: "auto",
    borderRadius: "10px", // Default border radius for the image
    [theme.breakpoints.down("md")]: {
      borderRadius: "5px", // Smaller border radius for medium screens
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0, // No border radius for small screens
    },
  },
  formCard: {
    width: "28%", // Default width for the form card
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "40px", // Default padding
    boxSizing: "border-box",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    backgroundColor: "white",
    borderRadius: "10px", // Default border radius
    marginTop: "100px",
    marginBottom: "100px",
    marginLeft: "100px",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      width: "40%", // Adjust width for medium screens
      marginLeft: "50px", // Adjust margin for medium screens
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%", // Full width for small screens
      borderRadius: 0,
      margin: "0",
      padding: "20px", // Adjust padding for small screens
    },
  },
  logo: {
    width: "110px", // Default logo size
    height: "auto",
    marginBottom: "10px",
    position: "absolute",
    top: "-40px", // Default top positioning for logo
    left: "50%",
    transform: "translateX(-50%)",   
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      top: "auto",
      left: "auto",
      transform: "none",
      marginTop: "23px",
      marginBottom: "20px", // Add margin bottom to space out the logo
      margin: "0 auto",
    },
  },
  heading: {
    fontFamily: "Inter",
    fontSize: "36px", // Default font size
    fontWeight: 600,
    lineHeight: "32px",
    textAlign: "left",
    marginBottom: "10px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px", // Adjust font size for small screens
      lineHeight: "28px",
    },
  },
  role: {
    fontFamily: "Inter",
    fontSize: "36px", // Default font size
    fontWeight: 600,
    lineHeight: "32px",
    textAlign: "left",
    color: theme.palette.primary.main,
    display: "block", 
    marginTop: "0px", 
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px", // Adjust font size for small screens
      lineHeight: "28px",
    },
  },
  tagline: {
    fontFamily: "Inter",
    fontSize: "20px", // Default font size
    fontWeight: 400,
    lineHeight: "24.2px",
    textAlign: "left",
    color: theme.palette.common.black,
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px", // Adjust font size for small screens
      lineHeight: "20px",
    },
  },
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  formGroup: {
    marginBottom: "10px",
  },
  contactContainer: {
    width: "100%", // Match the width of the image container
    height: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white", // White background
    borderRadius: 0, // No border radius
    padding: "20px",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      borderRadius: "0px",
      padding: "20px", // Smaller border radius for medium screens
    },
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide contact container on small screens
    },
  },
  contactHeading: {
    width: "321px",
    height: "64px",
    opacity: 1, // Set opacity to 1 to make it visible
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 600,
    lineHeight: "32px",
    textAlign: "left",
    color: "black", // Black text color
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "24px",
      marginLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none", // Hide contact container on small screens
    },
  },
  contactText: {
    width: "304px",
    height: "auto",
    opacity: 1, // Set opacity to 1 to make it visible
    fontFamily: "Inter",
    fontSize: "11px",
    fontWeight: 400,
    lineHeight: "13.31px",
    textAlign: "left",
    color: "black", // Black text color
  },
  contactEmail: {
    fontFamily: "Inter",
    fontSize: "11px",
    fontWeight: 400,
    lineHeight: "13.31px",
    textAlign: "left",
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
}));

export default useStyles;
