import {
  Box,
  Divider,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import useStyle from "./styles";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonIcon from "@mui/icons-material/Person";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import Logo from "../../assets/icons/Logo.svg";

function DHeader({
  navitems,
  title,
  close,
  open,
  anchor,
  name,
  favLength = 0,
  cartCount = 0,
  mobile,
}) {
  const theme = useTheme();
  const classes = useStyle();
  const location = useLocation();
  const navigate = useNavigate();
  const small = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorElGetStarted, setAnchorElGetStarted] = React.useState(null);
  const [anchorElAbout, setAnchorElAbout] = React.useState(null);

  const handleGetStartedClick = (event) => {
    setAnchorElGetStarted(event.currentTarget);
  };

  const handleAboutClick = (event) => {
    setAnchorElAbout(event.currentTarget);
  };

  const handleGetStartedClose = () => {
    setAnchorElGetStarted(null);
  };

  const handleAboutClose = () => {
    setAnchorElAbout(null);
  };

  const handleMenuItem = (path) => {
    handleGetStartedClose();
    handleAboutClose();
    navigate(path);
  };

  return (
    <AppBar
      elevation={0}
      position="fixed"
      style={{
        background: "transparent",
      }}
      className={classes.root}
    >
      <Toolbar className={classes.toolbar}>
        <Box sx={{ display: "flex", gap: small ? 0 : 4 }}>
          <RouterLink
            to={
              location.pathname === "/checkout"
                ? "/restaurant-list"
                : "/restaurant-list"
            }
            className={classes.linkDecoration}
          >
            <a href="#/">
              <img height="50" width="50" src={Logo} alt="Borku" />
            </a>
            {small ? null : (
              <Typography
                variant="h6"
                color={theme.palette.common.white}
                className={classes.font700}
                style={{ marginLeft: "8px" }}
              >
                {title}
              </Typography>
            )}
            </RouterLink>
            <Button
              id="get-started-button"
              aria-controls={
                Boolean(anchorElGetStarted) ? "get-started-menu" : undefined
              }
              aria-haspopup="true"
              aria-expanded={Boolean(anchorElGetStarted) ? "true" : undefined}
              onClick={handleGetStartedClick}
              sx={{
                color: theme.palette.common.white,
                fontSize: small ? 8 : 11,
              }}
            >
              Get Started
            </Button>
            <Menu
              id="get-started-menu"
              anchorEl={anchorElGetStarted}
              open={Boolean(anchorElGetStarted)}
              onClose={handleGetStartedClose}
              MenuListProps={{
                "aria-labelledby": "get-started-button",
              }}
            >
              <MenuItem
                onClick={() => handleMenuItem("/riderRegistration")}
                sx={{ color: "black" }}
              >
                Become a customer
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItem("/merchantRegistration")}
                sx={{ color: "black" }}
              >
                Become a merchant
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItem("/riderRegistration")}
                sx={{ color: "black" }}
              >
                Become a rider
              </MenuItem>
            </Menu>
            <Button
              id="about-button"
              aria-controls={Boolean(anchorElAbout) ? "about-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={Boolean(anchorElAbout) ? "true" : undefined}
              onClick={handleAboutClick}
              sx={{
                color: theme.palette.common.white,
                fontSize: small ? 8 : 11,
              }}
            >
              About
            </Button>
            <Menu
              id="about-menu"
              anchorEl={anchorElAbout}
              open={Boolean(anchorElAbout)}
              onClose={handleAboutClose}
              MenuListProps={{
                "aria-labelledby": "about-button",
              }}
            >
              <MenuItem
                onClick={() => handleMenuItem("/AboutUs")}
                sx={{ color: "black" }}
              >
                About Borku
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuItem("/ContactUs")}
                sx={{ color: "black" }}
              >
                Contact
              </MenuItem>
            </Menu>
          
        </Box>

        <Box className={classes.flex}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(event) => open(event)}
          >
            <PersonIcon
            fill={theme.palette.common.white}
              className={classes.icon}
            />
            {!mobile && (
              <Typography
                variant="button"
                color={theme.palette.common.white}
                noWrap
                className={`${classes.ml} ${classes.font700}`}
                style={{ maxWidth: 70 }}
              >
                {name}
              </Typography>
            )}

            {anchor === null ? (
              <ExpandMoreIcon
                fill={theme.palette.common.white}
                className={classes.icon}
              />
            ) : (
              <ExpandLessIcon
                fill={theme.palette.common.white}
                className={classes.icon}
              />
            )}
          </Button>
          <Divider flexItem orientation="vertical" light />
          <Menu
            id="long-menu"
            anchorEl={anchor}
            keepMounted
            open={Boolean(anchor)}
            onClose={close}
            getcontentanchorel={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            style={{
              marginTop: "48px",
            }}
            MenuListProps={{
              style: {
                background: "rgba(243, 244, 248, 0.8)",
                backdropFilter: "blur(6px)",
                padding: 0,
              },
            }}
          >
            {navitems.map((item, index) => (
              <Box key={index}>
                <RouterLink to={item.link} className={classes.linkDecoration}>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => close(item.title)}
                  >
                    <Typography color="textSecondary" variant="body2">
                      {item.title}
                    </Typography>
                  </MenuItem>
                </RouterLink>
              </Box>
            ))}
          </Menu>
          <Box display="flex" alignItems="center">
            <Box mr={theme.spacing(3)} ml={theme.spacing(3)}>
              <RouterLink to="/favourite">
                {favLength > 0 ? (
                  <FavoriteIcon className={classes.icon} fill={theme.palette.common.white} />
                ) : (
                  <FavoriteBorderIcon className={classes.icon} fill={theme.palette.common.white} />
                )}
              </RouterLink>
            </Box>
            {cartCount && (
              <>
                <Divider orientation="vertical" light />
                <RouterLink to="/checkout" className={classes.linkDecoration}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    ml={theme.spacing(3)}
                  >
                    <LocalMallIcon className={classes.icon} fill={theme.palette.common.white} />
                    <Typography
                      variant="caption"
                      color={theme.palette.common.white}
                      className={classes.cartText}
                    >
                      {cartCount}
                    </Typography>
                  </Box>
                </RouterLink>
              </>
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default React.memo(DHeader);
