import React from "react";
import useStyles from "./style";
import borkulogo from "../../assets/images/burko-logo.svg";

const Form = ({
  picture,
  heading,
  role,
  tagline,
  children,
  contactus,
  contactHeading,
  contactText,
  contactEmail,
  contactWhatsapp, 
  onSubmit, 
}) => {
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    if (onSubmit) {
      onSubmit(e); // Call onSubmit function passed from parent component
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img src={picture} alt="side profile template" className={classes.picture} />
        {contactus && (
          <div className={classes.contactContainer}>
            <h2 className={classes.contactHeading}>{contactHeading}</h2>
            <p className={classes.contactText}>
              {contactText}{" "}
              <a href={`mailto:${contactEmail}`} className={classes.contactEmail}>
                {contactEmail}
              </a>{" "}
              or WhatsApp at {" "}
              <a href={`https://wa.me/${contactWhatsapp.replace(/\s/g, "")}`} target="_blank" rel="noreferrer" className={classes.contactEmail}>
                {contactWhatsapp}
              </a>
            </p>
          </div>
        )}
      </div>
      <div className={classes.formCard}>
        <img src={borkulogo} alt="Logo" className={classes.logo} />
        {heading && (
          <h1 className={classes.heading}>
            {heading}
            <br />
            {role && <span className={classes.role}>{role}</span>}
            <br />
            {tagline && <span className={classes.tagline}>{tagline}</span>}
          </h1>
        )}
        <form className={classes.form} onSubmit={handleSubmit}>
          {children}
        </form>
      </div>
    </div>
  );
};

export default Form;
